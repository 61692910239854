<template>
  <div>
    <a-modal
      :visible="visible"
      :title="isUpdateMode ? '修改区域' : '创建区域'"
      width="40%"
      centered
      okText="确认"
      cancelText="取消"
      :destroyOnClose="true"
      @ok="() => onSubmit()"
      @cancel="() => hideDialog()"
    >
      <a-form-model layout="vertical" :rules="rules" :model="form" ref="form">
        <a-form-model-item label="区域名称" prop="name">
          <a-input width="100%" v-model="form.name" placeholder="请输入区域名称"> </a-input>
        </a-form-model-item>
        <a-form-model-item label="区域标识符" prop="identifier">
          <a-input :disabled="isUpdateMode" width="100%" v-model="form.identifier" placeholder="请输入区域标识符">
          </a-input>
        </a-form-model-item>
        <a-form-model-item label="区域服务器地址" prop="host">
          <a-input width="100%" v-model="form.host" placeholder="请输入区域服务器地址"> </a-input>
        </a-form-model-item>
        <a-form-model-item label="区域标签" prop="tag">
          <a-input width="100%" v-model="form.tag" placeholder="请输入区域标签"> </a-input>
        </a-form-model-item>
        <a-form-model-item label="区域推送" prop="push">
          <a-switch checked-children="开" un-checked-children="关" v-model="switchValue" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import clonedeep from 'lodash.clonedeep';

export default {
  name: 'RegionFormModal',
  components: {},
  mounted() {},
  data() {
    return {
      rules: {
        name: [{ required: true, message: '区域名称 不能为空', trigger: 'blur' }],
        host: [{ required: true, message: '区域服务器地址 不能为空', trigger: 'blur' }],
        identifier: [{ required: true, validator: this.identifierValidator, trigger: 'blur' }],
        tag: [{ required: true, message: '区域标签 不能为空', trigger: 'blur' }],
      },
      visible: false,
      isUpdateMode: false,
      form: {},
    };
  },
  methods: {
    cancel() {
      this.$router.back();
    },
    hideDialog() {
      this.visible = false;
    },
    showCreateDialog() {
      this.form = {};
      this.isUpdateMode = false;
      this.visible = true;
    },
    showUpdateDialog(pararms) {
      this.isUpdateMode = true;
      this.form = clonedeep(pararms);
      this.visible = true;
    },
    createRegion() {
      this.$refs.form.validate((relsValid) => {
        if (relsValid) {
          this.$apiManager.region.createRegions(this.form).then(() => {
            this.$message.success('创建成功');
            this.hideDialog();
            this.$parent.listRegions();
          });
        }
      });
    },
    updateRegion() {
      this.$refs.form.validate((relsValid) => {
        if (relsValid) {
          const params = {
            fields: ['name', 'host', 'creator', 'tag', 'pushStatus'],
            data: this.form,
          };
          this.$apiManager.region.updateRegions(params).then(() => {
            this.$message.success('修改成功');
            this.hideDialog();
            this.$parent.listRegions();
          });
        }
      });
    },
    onSubmit() {
      if (this.form.pushStatus === undefined) {
        this.$set(this.form, 'pushStatus', false);
      }
      if (this.isUpdateMode) {
        this.updateRegion();
      } else {
        this.createRegion();
      }
    },
    identifierValidator(rule, value, callback) {
      if (value === '') {
        callback('区域标识符 不能为空');
      }
      const regex = /\w+/;
      if (!regex.test(value)) {
        callback('区域标识符 由数字、字母、下划线组成');
      }
      callback();
    },
  },
  computed: {
    switchValue: {
      get() {
        return this.form.pushStatus ? this.form?.pushStatus === 1 : false;
      },
      set(value) {
        this.$set(this.form, 'pushStatus', value ? 1 : 2);
      },
    },
  },
};
</script>
